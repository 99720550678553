let body = document.body;
let style = getComputedStyle(body);

let start = StringHexToNumber(style.getPropertyValue('--start'));
let end = StringHexToNumber(style.getPropertyValue('--end'));
let degree = parseInt(style.getPropertyValue('--degree').replace("deg", ""));


const START_MAX_HEX = 0x3B719F;
const START_MIN_HEX = 0x2C5477;
const END_MAX_HEX   = 0x496456;
const END_MIN_HEX   = 0x384D42;

let startVar = 0x010101;
let endVar   = 0x010101;

function Start() : void {
    setInterval(() => {
        start += startVar;
        end += endVar;

        // Too High Case
        if (start > START_MAX_HEX) {
            startVar *= -1;
            start += startVar;
        }

        if (end > END_MAX_HEX) {
            endVar *= -1;
            end += endVar;
        }

        // Too Low Case
        if (start < START_MIN_HEX) {
            startVar *= -1;
            start += startVar;
        }

        if (end < END_MIN_HEX) {
            endVar *= -1;
            end += startVar;
        }

        degree++;

        body.style.setProperty('--start', `#${start.toString(16).toUpperCase()}`);
        body.style.setProperty('--end', `#${end.toString(16).toUpperCase()}`);
        body.style.setProperty('--degree', `${degree}deg`);
    }, 100);
}

function StringHexToNumber(hexNum : string) {
    return parseInt(hexNum.replace(/^#/, ''), 16);
}

export default Start;
